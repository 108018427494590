@import '/core.scss';

body {
  font-weight: 400;
}
section#cms-content {
  background: #ffffff;
  @include media(lg) {
    background: #f1f1f1;
  }
  .cms-content {
    @include media(lg) {
      background: #fff;
      border-radius: 5px;
      padding: 80px;
      box-shadow: 0 10px 20px #0000001C;
    }

    h2 {
      font-size: 1.5rem;
      margin-bottom: 25px;
      margin-top: 40px;
      &:first-child {
        margin-top: 0;
      }
    }

    ul {
      list-style: none;
      padding: 0;

      li {
        color: #333;
        margin-bottom: 10px;
      }
    }

    .icon {
      width: 20px;
      height: 20px;
      @include media(lg) {
        width: 22px;
        height: 22px;
      }
    }
  }

  img {
    border-radius: 5px;
    margin-top: 25px;
  }

  .btn-blue {
    display: table;
    border-radius: 3px;
    padding: 8px 22px;
    margin-bottom: 15px;
  }


}

.about #becomepartner {
  background: #fff!important;
}

.section-title {
  text-align: center;
}

b {
  color: #333;
  font-weight: 600;
}

p {
  color: #333;
}

.cms-content .entry-header {
  background: none;
  color: #000;
}